import "./Search.css";
import { Button } from '@mui/material';
import { actionTypes } from "../statemanagement/reducer";
//import { clientCache } from "../mock/ClientCache";
import { useNavigate } from "react-router-dom";
import "react-h5-audio-player/lib/styles.css"
import GooglePlayBadge from "../othercomponents/GooglePlayBadge";

const LandingPrompts = ({ write, dispatch, streaming, postFunc, user, headlines }) => {

    const navigate = useNavigate();

    const handleClick = (term, cache = false, edit = false) => {
        if (streaming) return;

        postFunc(edit);
        dispatch({ type: 'SET_SEARCH_TERM', term });
        // if (cache) {

        //     setTimeout(() => {
        //         dispatch({
        //             type: actionTypes.UPDATE_CONVERSATION_HISTORY,
        //             enrichedConversationHistory: clientCache(term)
        //         });
        //     }, 50);

        // } else {
        //     dispatch({ type: 'SET_SEARCH_TERM', term });
        // }

        window.gtag('event', 'Landing_Prompt_Assistance', {
            event_category: 'Landing_Prompt_Assistance',
            event_label: term,
        });

    };

    return (

        <div>

            <div className='prompt_assistance_button_group_countainer'>
                
                <div className={write ? 'prompt_assistance_button_group_write' : 'prompt_assistance_button_group'} style={{ paddingTop: "0.3%", marginBottom: "3%" }}>
                    {write && <>
                        <Button onClick={() => handleClick("Write a LinkedIn post about the benefits of AI in Education")}>
                            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {"Write a LinkedIn post about the benefits of AI in Education"}
                            </span>
                        </Button>
                        <Button onClick={() => handleClick("Create an SMS to promote the launch of a new exclusive clothing line")}>
                            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {"Create an SMS to promote the launch of a new exclusive clothing line 📩"}
                            </span>
                        </Button>
                        <Button onClick={() => handleClick("Write a newsletter email that promotes our new product and includes a Call-to-Action to visit our website for more information ")}>
                            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {"Write a newsletter email that promotes our new product and includes a Call-to-Action to visit our website for more information 📰"}
                            </span>
                        </Button>

                    </>
                    }

                    
                    <Button onClick={() => handleClick("Ayurvedic remedies for tiredness")}>
                        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {"Ayurvedic remedies for tiredness"}
                        </span>
                    </Button>
                    <Button onClick={() => handleClick("Should I work in Startup vs Large Company?")}>
                        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {"Should I work in Startup vs Large Company?"}
                        </span>
                    </Button>
                    
                    <Button onClick={() => handleClick("How do I deal with my bad boss?")}>
                        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {"How do I deal with my bad boss?"}
                        </span>
                    </Button>
                    {/* <Button onClick={() => handleClick("How to become a chess grandmaster?")}>
                        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {"How to become a chess grandmaster?"}
                        </span>
                    </Button>
                    <Button onClick={() => handleClick("What is the square-root of 4?")}>
                        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {"What is the square-root of 4?"}
                        </span>
                    </Button> */}
                    {/* <Button onClick={() => handleClick("रात को नींद नहीं आती, क्या करून?")}>
                        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {"रात को नींद नहीं आती, क्या करून?"}
                        </span>
                    </Button> */}
                    {/* <Button onClick={() => handleClick("मुझे सामान्य सर्दी के लिए घरेलू उपचार बताएं")}>
                        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {"मुझे सामान्य सर्दी के लिए घरेलू उपचार बताएं 🤧"}
                        </span>
                    </Button> */}

                    
                    {/* <Button onClick={() => navigate("/store")}>
                        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {"More AI Prompts .."}
                        </span>
                    </Button>  */}
                    <GooglePlayBadge />

                    
                    
                </div>



            </div>
        </div>
    );
};

export default LandingPrompts;

