import React from "react";
import { useState } from "react";
import { useStateValue } from "../statemanagement/StateProvider";
import "./BasicChat.css";
import Header from "./Header";
import AppFooter from "./AppFooter";
import { Button } from '@mui/material';
import { TypeAnimation } from "react-type-animation";

function BotStore() {
    const [index, setIndex] = useState(0);
    const [{}, dispatch] = useStateValue();

    return (
        <div>
            <div className="container" style={{ padding: "3.9%" }}>
                <Header showLogin={true} />

                <div className="container_copy">
                           <TypeAnimation sequence={[
                        
                             "Powered by Open AI, Microsoft",
                             300,
                             "Chat with AI Bots"

                        ]} wrapper="span" speed={2} style={{ fontSize: "1.3em", lineHeight: "2.1rem", color: "rgb(52, 52, 52)", fontWeight: "401", opacity: "0.9" }} />
                    </div>
                <div className="container_body" style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)", // Creates three columns
                    gridTemplateRows: "repeat(5, 1fr)", // Creates five rows
                    gap: "10px", // Adds some space between the buttons
                }}>
                    
                    {Array.from({ length: 15 }).map((_, idx) => (
                        <Button
                            key={idx}
                            style={{
                                paddingRight: "20px",
                                color: "black",
                                textTransform: "none",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            onClick={async _ => {
                                // Example action
                                // dispatch({type: "SET_SEARCH_TERM", term:"astro:"});
                                console.log("Navigating to /friend");
                            }}
                        >
                            <img src="/saree.webp" alt="WhatsApp" style={{ width: "50px", borderRadius:"50%", textDecoration: "none" }} />
                            <div style={{ fontSize: "13px", opacity: "0.7", marginTop: "3px" }}>AI Friend</div>
                        </Button>
                    ))}
                </div>
            </div>
            <AppFooter showHome={false} showSearch={false} showNumerology={false} showWrite={true} />
        </div>
    );
}

export default BotStore;