import { useEffect, useState } from "react";

import { useStateValue } from "../statemanagement/StateProvider";
import { actionTypes } from "../statemanagement/reducer";

import axios from "axios";
import { getFeed } from "../components/constants/helpers"

const useFeed = (page = 1) => {

    //hold the feed content
//    const [content, setContent] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [{headlines}, dispatch] = useStateValue();

    //dependency on page
    useEffect(()=>{

        const fetchOffsettedHeadlines = async () => {
          //  console.log("calling api again");
            
            try {
              //  console.log(`Headlines: `);
               // console.log(headlines);
                // if(headlines.length>0)
                //     return;
                setIsLoading(true);
                //TEST
          //      const response = await axios.get(`${getFeed()}?offset=${headlines.length}`); 
                const response = await axios.get(`${getFeed()}feed/random`); //http://localhost:8082/feed/random
                dispatch({type: actionTypes.UPDATE_HEADLINES, headlines: [...headlines, ...response.data.headlines]})
                setHasNextPage(Boolean(response.data.headlines))
           //setHasNextPage(false);
            } catch(e){
                //replace with generic content and alert! 
                dispatch({type: actionTypes.UPDATE_HEADLINES, headlines: []})
                setHasNextPage(false);
            } finally {
                setIsLoading(false);
            }
        }
        fetchOffsettedHeadlines();
    },[page])

    return {isLoading, hasNextPage};
}

export default useFeed;