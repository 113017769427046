import React, { useEffect, useRef, useState } from 'react';
import { useStateValue } from '../statemanagement/StateProvider';
import { actionTypes } from '../statemanagement/reducer';
import "./Results.css";
import "./BasicChat.css"
import Refine from "../components/AskFollowUp";
import {useNavigate} from "react-router-dom"
import AtomicSpinner from 'atomic-spinner';
import { Button } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import "./BasicChat.css"
import AudioPlayer from '../helpers/AudioPlayer';
import useChefBot from '../helpers/useChefBot';
import useVakilBot from '../helpers/useVakilBot';

function VakilBot() {
  
  const [{ term, followup, conversationHistory, loading, streaming, nextQuestion, chatMode, user, subscription, mode }, dispatch] = useStateValue();
  
  const refToScrollableDiv = useRef(null);
  useVakilBot(term, followup);

  const displayConversationHistory = JSON.parse(JSON.stringify(conversationHistory));
  
  const [autoScroll, setAutoScroll] = useState(true);

  const navigate = useNavigate();

  const handleCopyClick = async (u) => {
    try {
      const parser = new DOMParser();
      const parsedHtml = parser.parseFromString(u, 'text/html');
      const textToCopy = parsedHtml.body.textContent || '';
      await navigator.clipboard.writeText(textToCopy);
      console.log("paste and verify");
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

useEffect(()=>{
    console.log("mount");
    dispatch({type:actionTypes.SET_SEARCH_TERM, term:"AI Vakil"})
},[])

useEffect(() => {
  
  if(conversationHistory.length<2) return;

  if(!refToScrollableDiv.current) return;

  if(autoScroll && streaming){ 
    const { scrollHeight } = refToScrollableDiv.current;
    refToScrollableDiv.current.scrollTo(0, scrollHeight);
  }
  if(followup.length>0){
    const { scrollHeight } = refToScrollableDiv.current;
    refToScrollableDiv.current.scrollTo(0, scrollHeight);
  }
  
},[conversationHistory, autoScroll, followup]); 

useEffect(()=>{
  if(term.length>0 || followup.length>0) setAutoScroll(true);
},[term, followup])

useEffect(()=>{
  const scrollContainer = refToScrollableDiv.current;
  if(scrollContainer){
    scrollContainer.addEventListener("mousedown", ()=>setAutoScroll(false));
    scrollContainer.addEventListener("touchstart", ()=>setAutoScroll(false));
    scrollContainer.addEventListener("click", ()=>setAutoScroll(false));
  }

  return () => {
    if(scrollContainer){
      scrollContainer.removeEventListener("mousedown", ()=>setAutoScroll(false));
      scrollContainer.removeEventListener("touchstart", ()=>setAutoScroll(false));
      scrollContainer.removeEventListener("click", ()=>setAutoScroll(false));
    }
  }

},[])

const focusButtonStyle = () => {
  return {borderRadius: "18px", opacity:"0.9", marginLeft:"5px",  marginRight:"5px", boxShadow:"2px 4px 12px rgba(0,0,0,.5)", background: "black", paddingLeft:"16px", marginRight:"8px", fontSize:"small", color:"white"};
}


  return (
    <div className='resultsPage'>
                  {/* {
                    <div className='resultsPage__header'>
                      <div style={{display:"flex", alignItems:"baseline"}} className='resultsPage__header__prompt__entry'>
                          <AllInclusiveIcon style={{marginRight:"2.5%"}} onClick={(_)=> {dispatch({type:actionTypes.RESET_STATE});; navigate("/");}} />  
                         
                        {false && <Search w={"97%"} placeholder={"Ask AI anything"} showClear={true} />}
                        {chatMode && <h1 onClick={(_)=> {dispatch({type:actionTypes.RESET_STATE});; navigate("/query"); }} style={{ fontWeight: 500, opacity:"0.9", fontFamily: "'Roboto', sans-serif" }}>ChatDragon.ai</h1>}
                      </div>
                    </div>
            } */}
  
        <div className='resultsPage__body_gita' ref={refToScrollableDiv}>
        <div style={{textAlign: "center",  borderRadius: "30%", padding: "7px"}}>
    <img src="/lawyer_ai.webp" style={{width: "75vw", borderRadius: "20%", height:"35vh"}}/> <br/> <br/>
    <i>Disclaimer: The AI Vakil is designed to provide helpful legal information and guidance to users based on Indian law. The information offered by this AI bot is for informational purposes only and should not be considered as legal advice. We recommend consulting with a qualified legal professional for specific legal issues. Any reliance you place on such information is therefore strictly at your own risk.</i>
</div>

            <div className='resultsPage__ai__response'>
              

            {(
              
              displayConversationHistory
               // .reverse() // This will reverse the order of the array
                .filter(u=>u.role === "assistant" || u.role === "user")
                .map((u, _) => {
                  if(u.role == "user"){
                    return (<div>
                              <h2 style={{padding:"3%", paddingBottom:"3%"}}>{u.content}</h2> 
                            </div>)
                  }
                  return (<div className='ai__display'>
                            <div className='ai__display__assistant'> 
                              {
                                <>
                                    <div style={{whiteSpace:"pre-line", padding:"3%"}} dangerouslySetInnerHTML={{__html: u.content}}></div>
             
                                    
                                    {/* <AudioPlayer voiceId={"XB0fDUnXU5powFXDhCwa"} streaming={streaming} conversationHistory={conversationHistory}/> */}
                                    {!streaming && <Button onClick={async (e) => {
                                      await handleCopyClick(u.content);
                                      window.gtag('event', 'Copy');
                                    }} style={{marginLeft:"80%"}} startIcon={<ContentCopyIcon/>}>Copy</Button>}
                                  
                                  <hr style={{marginTop:"1%", opacity:"0.3"}}/>
                                </>
                              }
                            </div>
                          </div>)
                })
            )}
            { loading && <div className='ai__display'>
                            <div className='ai__display__assistant'> <div className='resultsPage__ai__response__spinner' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
               <AtomicSpinner style={{ height: '100px !important' }} />
              </div></div></div>
            }

            </div>
            <div className='buffer' style={{height:"25vh"}}></div>
        </div>
        <div className='resultsPage__footer'>         
           <Refine refToScrollableDiv = {refToScrollableDiv} showQuestions={true} placeholder='e.g. Steps to register a new company in India' />
        </div>
      
    </div>
  );
}

export default VakilBot;

