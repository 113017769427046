export const initialState = {
    term: "",
    followup: "",
    conversationHistory:[],
    loading: false,
    streaming: false,
    nextQuestion:"",
    nextQuestionLoading:false,
    chatMode: false,
    editing: false,
    user:null,
    products: [],
    unsignedCount:"0",
    subscription:null,
    loginLoading: false,
    paymentInfo: null,
    meter: 0,
    headlines:[],
    webaccess: false,
    autoplayAllowed: false,
    mode: "search"
};

export const actionTypes = {
    SET_SEARCH_TERM: "SET_SEARCH_TERM",
    SET_AUTOPLAY_ALLOWED: "SET_AUTOPLAY_ALLOWED",
    UPDATE_CONVERSATION_HISTORY: "UPDATE_CONVERSATION_HISTORY",
    SET_FOLLOWUP_TERM: "SET_FOLLOWUP_TERM",
    SET_STREAMING:"SET_STREAMING",
    SET_LOADING:"SET_LOADING",
    SET_NEXT_Q:"SET_NEXT_Q",
    SET_NEXT_Q_LOADING:"SET_NEXT_Q_LOADING",
    SET_CHAT_MODE: "SET_CHAT_MODE",
    RESET_STATE: "RESET_STATE",
    SET_EDITING: "SET_EDITING",
    SET_USER: "SET_USER",
    SET_STRIPE_CUSTOMER: "SET_STRIPE_CUSTOMER",
    ENRICH_STRIPE_CUSTOMER: "ENRICH_STRIPE_CUSTOMER",
    SET_STRIPE_SUBSCRIPTION: "SET_STRIPE_SUBSCRIPTION",
    SET_LOGIN_LOADING: "SET_LOGIN_LOADING",
    SET_PAYMENT_INFO: "SET_PAYMENT_INFO",
    UPDATE_METER:"UPDATE_METER",
    UPDATE_HEADLINES: "UPDATE_HEADLINES",
    SET_WEB_ACCESS:"SET_WEB_ACCESS",
    SET_MODE:"SET_MODE"
}

const reducer = (state, action) => {

 //   console.log(`Current State: ${JSON.stringify(state)}`);
   // console.log(`Action: ${JSON.stringify(action)}`)

    switch(action.type){
        case actionTypes.UPDATE_METER:
                return {...state, meter: action.meter}
        
        case actionTypes.SET_MODE:
                console.log(`Setting mode to: ${action.mode}`)
                return {...state, mode: action.mode}
        case actionTypes.SET_AUTOPLAY_ALLOWED:
                console.log(`Allowing autoplay`);
                return {...state, autoplayAllowed: action.autoplayAllowed }
        case actionTypes.SET_PAYMENT_INFO:
                return {...state, paymentInfo: action.paymentInfo}
        case actionTypes.SET_WEB_ACCESS:
                return {...state, webaccess: action.webaccess}
        case actionTypes.SET_USER:
                return {...state, user: action.user}
        case actionTypes.UPDATE_HEADLINES:
                return {...state, headlines: action.headlines}
        case actionTypes.SET_LOGIN_LOADING:
                return {...state, loginLoading: action.loginLoading}
        case actionTypes.SET_STRIPE_SUBSCRIPTION:
                return {...state, subscription: action.subscription}
        case actionTypes.SET_EDITING:
                return {...state}
        case actionTypes.SET_STRIPE_CUSTOMER:
                return {...state, stripeCustomer: action.stripeCustomer }
        case actionTypes.ENRICH_STRIPE_CUSTOMER:
                return {...state, stripeCustomer:{...state.stripeCustomer, subscriptions:action.subscriptions}}
        case actionTypes.RESET_STATE:
                return {...initialState, user:state.user, webaccess: state.webaccess, headlines: state.headlines};
        case actionTypes.SET_STREAMING:
                const update = {...state, streaming: action.streaming}; 
                return update;
        case actionTypes.SET_CHAT_MODE:
                const newState = {...state, chatMode: action.chatMode}; 
                return newState;
        case actionTypes.SET_SEARCH_TERM: 
            
            const updatedState = {
                ...state,
                term: action.term,
                followup: "", 
                 loading: true,  
                nextQuestion:"", 
                SET_CHAT_MODE: false,
                conversationHistory: [{"role":"user", "content": action.term}] 
            };
            return updatedState;
        case actionTypes.SET_NEXT_Q:
            return {...state,
            nextQuestion: action.next, 
        loading: false}
        case actionTypes.SET_NEXT_Q_LOADING:
            return {...state, nextQuestionLoading: action.nextQuestionLoading, editing: false}
        case actionTypes.SET_FOLLOWUP_TERM:
          //  console.log("Set followup");
                return {
                    ...state, 
                    followup: action.followup,
                    loading: true,
                    nextQuestion:"",
                    conversationHistory: [...state.conversationHistory, {"role":"user", "content": action.followup}]
                }
        case actionTypes.UPDATE_CONVERSATION_HISTORY: //conversation history filled, 
                {
                   // console.log(`Updating Conversation History state to:`);
                let res = {
                    ...state,
                    term:"",
                    followup:"",
                    conversationHistory: action.enrichedConversationHistory
                };
                return res;
                }
        case actionTypes.SET_LOADING:
                return {
                    ...state,
                    loading: action.loading
                }
        default: 
            return state;
    }
}

export default reducer;
