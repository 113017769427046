import Header from "./Header";
import AppFooter from "./AppFooter";
import "./BasicChat.css"
import { useStateValue } from "../statemanagement/StateProvider"; 
import CardComponent from "../components/CardComponent"
import useFeed from "../customhooks/useFeed";
import { useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import AudioComponent from "../components/AudioComponent";
import Disclaimer from "../components/Disclaimer";
import { actionTypes } from "../statemanagement/reducer";

const ContentFeed = () => {

    const [{headlines, streaming}, dispatch] = useStateValue(); 
    const [page, setPage] = useState(1);
    const {isLoading, hasNextPage} = useFeed(page);
    const [currentIdPlaying, setCurrentIdPlaying] = useState(null);
    const navigate = useNavigate();

    const intersectionObserver = useRef(null);
    const ref = useRef(null);

    const [hasInteracted, setHasInteracted] = useState(false);

    // useEffect(() => {
    //     const handleScroll = () => {
    //       if(!hasInteracted){
    //         setHasInteracted(true);
    //       }
    //     };
    
    //     window.addEventListener('scroll', handleScroll);
    
    //     return () => {
    //       window.removeEventListener('scroll', handleScroll);
    //     };
    //   }, []);


    const lastChildRef = useCallback(lastElement => {
 
        if(isLoading) return;

        if(intersectionObserver.current) intersectionObserver.current.disconnect();

        intersectionObserver.current = new IntersectionObserver(children => { 
  
            if(children[0].isIntersecting && hasNextPage){

                setPage(p=>p+1);
            }
        })

        if(lastElement){
            intersectionObserver.current.observe(lastElement); 
        }

    },[isLoading, hasNextPage])

    const handleClick = (term, cache = false, edit = false) => {
        if (streaming) return;

        navigate("/query"); 
        dispatch({ type: 'SET_SEARCH_TERM', term });

        window.gtag('event', 'Landing_Prompt_Assistance', {
            event_category: 'Landing_Prompt_Assistance',
            event_label: term,
        });

    };

    const constructFeed = (headlines) => {
        return headlines.map((headline,i)=>{            
            if((i+1) == headlines.length){
                return <><CardComponent  voice={!!headline.voice ? headline.voice:"" }  
                summary={!!headline.summary ? headline.summary:"" } 
                id={!!headline.id ? headline.id : "0"} 
                dispatch={dispatch} 
                handleClick={(name)=>handleClick(name, false, false)} 
                key={i} ref={lastChildRef} source={headline.provider[0].name} 
                name={headline.name} body={headline.description} image={!!headline.image ? headline.image.contentUrl : ""}
                interacted={hasInteracted} /><br/></>;
            }
            return <><CardComponent tryfunc = {() => console.log("tryfunc called from child")} voice={!!headline.voice ? headline.voice:"" } summary={!!headline.summary ? headline.summary:"" }  
                                  question={!!headline.question ? headline.question : ""}  
                                  id={!!headline.id ? headline.id : "0"}  dispatch={dispatch} 
                                  handleClick={(name)=>handleClick(name, false, false)}
                                  key={i} 
                                  source={headline.provider[0].name} name={headline.name} body={headline.description} 
                                  image={!!headline.image ? headline.image.contentUrl : ""}
                                  interacted={hasInteracted} /><br/></>;
        })
    }

    return (<div>
           
        <div className="container" style={{padding:"3.5%"}}>
            <Header />
            
            <div className="container_body" >
            
            {/* <AudioComponent/> */}
             <br/>
            {/* <Disclaimer hindi={false}/> <br/> */}<br/>
           
            {/* <CardComponent dispatch={dispatch} source={"India.com on MSN.com"}
            handleClick={(name)=>handleClick(name, false, false)}
            name={"Shaitaan AI Review (3/5)"} body={""} 
            image={"https://img-s-msn-com.akamaized.net/tenant/amp/entityid/BB1jFrE8.img?w=700&h=472&m=4&q=91"} 
            id={"20"} voice={"AI Aaryan, Majestic & Deep (Hindi)"} 
            /> */}
            {/* <CardComponent dispatch={dispatch} source={"स्त्रोत: भारत.कॉम ऑन एमएसएन.कॉम"}
            handleClick={(name)=>handleClick(name, false, false)}
            name={"शैतान एआई समीक्षा (3/5)"} body={""} 
            image={"https://img-s-msn-com.akamaized.net/tenant/amp/entityid/BB1jFrE8.img?w=700&h=472&m=4&q=91"} 
            id={"20"} voice={"आवाज़: एआई आर्यन, महाकाव्यिक और गहरा"} 
            /> */}


           {/* <CardComponent dispatch={dispatch} source={"India.com on MSN.com"}
            //handleClick2={(name)=>handleClick2(name, false, false)}  
            handleClick={(name)=>handleClick(name, false, false)} 
            name={"Shaitaan AI Review (3/5)"} body={""} 
            image={"https://img-s-msn-com.akamaized.net/tenant/amp/entityid/BB1jFrE8.img?w=700&h=472&m=4&q=91"} 
            id={"20"} voice={"AI Aaryan, Majestic & Deep (Hindi)"} 
            /> */}

            {constructFeed(headlines)}
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            </div>
        </div>
        <AppFooter showHome={false} showDiscover={false} showWrite={true} showNumerology={false} showFeed={false} />
    </div>);
}

export default ContentFeed;


