import React from "react";
import "./BasicChat.css";
import { useNavigate } from "react-router-dom";
import { Button } from '@mui/material';
import { actionTypes } from "../statemanagement/reducer";
import { useStateValue } from "../statemanagement/StateProvider";

const AppFooter = ({ showSearch = true, showHome = true, shareUrl = "", showPost = true, showDiscover = true, showNumerology = false, showWrite = false, showFeed=true }) => {
    const location = useNavigate();
    const [{ }, dispatch] = useStateValue();
    return (
        <div className="footer" style={{ background: "white", display: "flex", position: "fixed", bottom: "0", width: "100%", flexDirection: "column", borderRadius: "10px 10px 0px 0px" }}>
            <div className="footer__inner" style={{ margin: "auto", alignItems: "center", display: "flex", paddingTop: "0.3rem", paddingBottom: "0.3rem", color: "white", fontSize: "13px", fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}>

                    {false && <Button
                    style={{
                        
                        color: "black",
                        textTransform: "none",
                        display: "flex", // Use flex layout
                        flexDirection: "column", // Arrange children in a column
                        alignItems: "center", // Center-align items horizontally
                        justifyContent: "center", // Center-align items vertically
                    }}
                    onClick={async _ => {
                        //  dispatch({type: actionTypes.SET_SEARCH_TERM, term:"astro:"})
                        location("/write");

                    }}>
                    <img src="/writer.png" alt="WhatsApp" style={{ width: "29px", textDecoration: "none" }} />
                    <div style={{ fontSize: "13px", opacity: "0.7", marginTop: "3px" }}>AI Writer</div>
                </Button>}

                {(showSearch || showHome) && <Button
                    style={{
                        paddingRight: "20px",
                        color: "black",
                        textTransform: "none",
                        display: "flex", // Use flex layout
                        flexDirection: "column", // Arrange children in a column
                        alignItems: "center", // Center-align items horizontally
                        justifyContent: "center", // Center-align items vertically
                    }}
                    onClick={async _ => {
                        dispatch({ type: actionTypes.SET_WEB_ACCESS, webaccess: false });
                        location("/");
                    }}>
                    {/* <img src="/icons/search.png" alt="WhatsApp" style={{ width: "25px", textDecoration: "none" }} /> */}
                    <img src="/icons/brain.png" style={{ width: "36px", height: "36px" }}/>
                    <div style={{ fontSize: "13px", opacity: "0.7" }}>Home</div>
                </Button>}

                {/* <Button style={{ paddingRight: "20px", color: "black", textTransform: "none", display:"flex" }} onClick={async _ => {
                    try {
                        const url = "https://tigerchat.co.in";
                        const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(url)}`;
                        window.open(whatsappUrl, '_blank');
                        window.gtag('event', 'Share');
                    } catch (_) {
                        console.error("Failed to Copy");
                    }
                }}><img src="/icons/whatsapp.png" alt="WhatsApp" style={{ marginRight: 5, width: "25px", textDecoration: "none" }} />
                <div style={{fontSize:"13px", opacity:"0.7"}}>Share</div> </Button> */}


                {true && <Button
                    style={{
                        
                        color: "black",
                        textTransform: "none",
                        display: "flex", // Use flex layout
                        flexDirection: "column", // Arrange children in a column
                        alignItems: "center", // Center-align items horizontally
                        justifyContent: "center", // Center-align items vertically
                    }}
                    onClick={async _ => {
                        try {
                            const url = "https://tigerchat.co.in";
                            const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(url)}`;
                            window.open(whatsappUrl, '_blank');
                            window.gtag('event', 'Share');
                        } catch (_) {
                            console.error("Failed to Copy");
                        }
                    }}>
                    <img src="/icons/whatsapp.png" alt="WhatsApp" style={{ width: "29px", textDecoration: "none" }} />
                    <div style={{ fontSize: "13px", opacity: "0.7", marginTop: "5px" }}>Share </div>
                </Button>}
               

                {false && <Button
                    style={{
                        paddingRight: "20px",
                        color: "black",
                        textTransform: "none",
                        display: "flex", // Use flex layout
                        flexDirection: "column", // Arrange children in a column
                        alignItems: "center", // Center-align items horizontally
                        justifyContent: "center", // Center-align items vertically
                    }}
                    onClick={async _ => {
                        //  dispatch({type: actionTypes.SET_SEARCH_TERM, term:"astro:"})
                        location("/friend");

                    }}>
                    <img src="/saree.webp" alt="WhatsApp" style={{ width: "29px", textDecoration: "none", borderRadius:"30%" }} />
                    <div style={{ fontSize: "13px", opacity: "0.7", marginTop: "3px" }}>AI Friend</div>
                </Button>}

                {false && <Button
                    style={{
                        paddingRight: "20px",
                        color: "black",
                        textTransform: "none",
                        display: "flex", // Use flex layout
                        flexDirection: "column", // Arrange children in a column
                        alignItems: "center", // Center-align items horizontally
                        justifyContent: "center", // Center-align items vertically
                    }}
                    onClick={async _ => {
                        //  dispatch({type: actionTypes.SET_SEARCH_TERM, term:"astro:"})
                        location("/gita");

                    }}>
                    <img src="/ganesha.png" alt="WhatsApp" style={{ width: "29px", textDecoration: "none" }} />
                    <div style={{ fontSize: "13px", opacity: "0.7", marginTop: "3px" }}>AI Gita</div>
                </Button>}

                {/* {showFeed && <Button
                    style={{
                        
                        color: "black",
                        textTransform: "none",
                        display: "flex", // Use flex layout
                        flexDirection: "column", // Arrange children in a column
                        alignItems: "center", // Center-align items horizontally
                        justifyContent: "center", // Center-align items vertically
                    }}
                    onClick={async _ => {
                        //dispatch({type: actionTypes.UPDATE_HEADLINES, headlines:[]})
                        dispatch({ type: actionTypes.UPDATE_HEADLINES, headlines: [] })
                          location("/contentfeed");
                      //window.location.href = "https://play.google.com/store/apps/details?id=com.tigerchat.app";
                    }}>
                    <img src="/icons/content.png" alt="WhatsApp" style={{ width: "29px", textDecoration: "none" }} />
                    <div style={{ fontSize: "13px", opacity: "0.7", marginTop: "3px" }}>AI Content </div>
                </Button>} */}
                
                {/* <GooglePlayBadge/> */}


                {/* {showWrite && <Button
                    style={{
                        paddingRight: "20px",
                        color: "black",
                        textTransform: "none",
                        display: "flex", // Use flex layout
                        flexDirection: "column", // Arrange children in a column
                        alignItems: "center", // Center-align items horizontally
                        justifyContent: "center", // Center-align items vertically
                    }}
                    onClick={async _ => {
                        location("/write")
                    }}>
                    <img src="/icons/pencil.png" alt="WhatsApp" style={{ width: "28px", textDecoration: "none" }} />
                    <div style={{ fontSize: "13px", opacity: "0.7", marginTop: "3px" }}>AI Writer</div>
                </Button>} */}

                {false && <Button
                    style={{

                        color: "black",
                        textTransform: "none",
                        display: "flex", // Use flex layout
                        flexDirection: "column", // Arrange children in a column
                        alignItems: "center", // Center-align items horizontally
                        justifyContent: "center", // Center-align items vertically
                    }}
                    onClick={async _ => {
                        location("/business")
                    }}>
                    <img src="/icons/business.png" alt="WhatsApp" style={{ width: "25px", textDecoration: "none" }} />
                    <div style={{ fontSize: "13px", opacity: "0.7", marginTop: "3px" }}>Business</div>
                </Button>}
            </div>
        </div>
    );
}

export default AppFooter;