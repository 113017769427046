import React from "react";
import Search from '../components/Search';
import { useStateValue } from '../statemanagement/StateProvider';
import { actionTypes } from '../statemanagement/reducer';
import "./BasicChat.css"
import { TypeAnimation } from "react-type-animation";
import { modes } from "../mock/PromptAssistanceExamples";
import { useState, useEffect } from "react";
import Header from "./Header";
import AppFooter from "./AppFooter";
import AudioComponent from "../components/AudioComponent";
import Disclaimer from "../components/Disclaimer";
import BotDisplay from "../components/BotDisplay";
import Footer from "./Footer";


function BasicChat() {
    const [index, setIndex] = useState(0);
    const [{}, dispatch] = useStateValue();


    useEffect(() => {
        dispatch({ type: actionTypes.SET_STREAMING, streaming: false });
        const interval = setInterval(() => {
            setIndex(u => (u + 1) % modes.length)
        }, 4100);
        return () => clearInterval(interval);
    }, [])

    // useEffect(()=>{
    //     dispatch({type: actionTypes.UPDATE_CONVERSATION_HISTORY, conversationHistory:[]})
    // },[])

    // useEffect(()=>{
    //     dispatch({type:actionTypes.SET_MODE, mode:"search"});
    // },[])

    return (
        <div>
           
            <div className="container" style={{padding: "3.9%"}}>
                <Header showLogin={true} />
                
                {/* <br/>
                <AudioComponent/> */}
                <div className="container_body" >
                    <div className="container_copy">
                           <TypeAnimation sequence={[
                        
                             "Ask AI anything",
                             300,
                            //  "2,00,000+ users trust TigerChat AI",
                            //  300,
                            //  "AI trained on 10 trillion+ tokens",
                             

                        ]} wrapper="span" speed={2} style={{ fontSize: "1.4em", lineHeight: "2.1rem", color: "rgb(52, 52, 52)", fontWeight: "401", opacity: "0.9" }} />
                    </div>
                    
                    <Search home={true} showButtons={true} mode={"landing"} placeholder={""} route={true} style={{ fontWeight: 400 }} />
                    {/* <BotDisplay/> <br/> */}
                
                   
                    {/* <BotDisplay/> */}
                    <br/> <br/>
                    <Disclaimer/> <br/> <br /> <br/> <br />
                    <br/> <br /> <br/> <br />
                    
                </div>
            </div>
            <AppFooter showHome={false} showSearch={false} showNumerology={false} showWrite={true}  />
            {/* <Footer /> */}
        </div>
    );
}

export default BasicChat;