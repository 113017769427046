import { useEffect } from "react";
import axios from "axios";
import { actionTypes } from '../statemanagement/reducer';
import { useStateValue } from "../statemanagement/StateProvider"
import { getBasicChatLanguageModel, getWritingAssistantModel, getBasicChatLanguageModelAstro } from "../components/constants/helpers";

let cancelSource;

const useAIService = (term, followup) => {

  const [{ conversationHistory, streaming, webaccess }, dispatch] = useStateValue();

   const prodUrl = "https://infolitsearchservice.azurewebsites.net";
  const localUrl = "http://localhost:8088";
  //const prodUrl =  "http://localhost:8088";

  // useEffect(() => {



  //   const fetch = async () => {
  //     window.gtag('event', 'audio');
  //     dispatch({ type: actionTypes.SET_NEXT_Q_LOADING, nextQuestionLoading: true });
  //     const response = await axios.post(`${prodUrl}/question`, { messages: conversationHistory });

  //     if (!!response["data"] && response["data"] != "") {
  //       dispatch({
  //         type: actionTypes.SET_NEXT_Q,
  //         next: response["data"].includes(":") ? response["data"].split(":")[1].trim() : response["data"]
  //       });
  //     }
  //     dispatch({ type: actionTypes.SET_NEXT_Q_LOADING, nextQuestionLoading: false });
  //   }


  //   if (!streaming && conversationHistory.length > 0 && !!conversationHistory[conversationHistory.length - 1]["role"] && conversationHistory[conversationHistory.length - 1]["role"] == "assistant") {
  //     fetch();
  //   }

  // }, [conversationHistory, streaming]);

 

  const gptService = async (signal) => {

    let accumulatedResponse = ``;
   // let amendReference = ``;
    let topUrl = ``;

    if(webaccess){
        const res = await axios.post(`${prodUrl}/get-top-url`, {"messages": conversationHistory});
        topUrl = res.data.url;
    }   

    //setting signal 
    console.log("setting signal")
    const response = await fetch(webaccess ? `${prodUrl}/gpt-service-search`: getBasicChatLanguageModel(),{ 
      method: "POST",
      headers: {
        Accept: "text/html",
        "Content-Type":"application/json"
      },
      body: JSON.stringify({ "messages": conversationHistory, "source":topUrl }),
      signal: signal
    });

    if(!response.ok || !response.body){
      throw response.statusText;
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder();

    let loopCount = 0;
    const spaUpdateInterval = 1; 
    
    dispatch({
      type:  actionTypes.SET_STREAMING,
      streaming: true
    }); 

    dispatch({
      type: actionTypes.SET_LOADING,
      loading: false
    }); 

    while (true) {
      const {value, done } = await reader.read();
      if(done) { 
        
       // accumulatedResponse+=amendReference;

        dispatch({
          type: actionTypes.UPDATE_CONVERSATION_HISTORY,
          enrichedConversationHistory:[...conversationHistory, {role:"assistant", content:accumulatedResponse}]
        }) 
        dispatch({
          type:  actionTypes.SET_STREAMING,
          streaming: false
        }); 
        break; 
      }
      const decodedChunk = decoder.decode(value, {stream: true});
      accumulatedResponse+=decodedChunk; 
      if(loopCount % spaUpdateInterval === 0){ 
        dispatch({
          type: actionTypes.UPDATE_CONVERSATION_HISTORY,
          enrichedConversationHistory:[...conversationHistory, {role:"assistant", content:accumulatedResponse}]
        }) 
      }
      loopCount++;
    }

    
    dispatch({
      type: actionTypes.UPDATE_CONVERSATION_HISTORY,
      enrichedConversationHistory:[...conversationHistory, {role:"assistant", content:accumulatedResponse}]
    }) ;
  
  }


  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (term.length > 0) {
      console.log("passing in signal object")
      gptService(signal);
    }

    return () => {
      if(streaming){
        //if still streaming
        console.log("still streaming and component unmounted");
        console.log("initiating abort");
        controller.abort();
        console.log("cleaning state");
        dispatch({type:actionTypes.RESET_STATE})
      }
    }

  }, [term]);


  useEffect(() => {
    

    if (followup.length > 0) {
      gptService();
    }
    
  }, [followup]);
};



export default useAIService;
